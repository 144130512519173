<script>
import { onLineRecharge, onLineRecharge1 } from "@/api/recharge";
export default {
  data() {
    return {
      money_other: "", //支付金额
      id: "", //id
      title: "", //标题
      min: 0, //最小金额
      max: 0, //最大金额
      need_params: "", //特殊条件
      imgTrue: {
        tf: false,
        url: "",
      }, //二维码图片
      listType: 0, //支付类型
      size: 200, //二维码大小
      remark: null,
      items: [
        {
          value: "50",
          active: false,
        },
        {
          value: "100",
          active: false,
        },
        {
          value: "200",
          active: false,
        },
        {
          value: "500",
          active: false,
        },
        {
          value: "1000",
          active: false,
        },
        {
          value: "3000",
          active: false,
        },
      ],
    };
  },
  components: {
    OnlineTEMPLATE1: () => import("./OnlineTEMPLATE1.vue"),
  },
  methods: {
    formSubmit(data, type) {
      let paramsKey = Object.keys(data.data);
      if (!paramsKey || paramsKey.length <= 0) {
        app.Mint.Toast(getWord("no_result5"));
        return false;
      }
      let form1 = document.createElement("form");
      form1.id = "form1";
      form1.name = "form1";
      document.body.appendChild(form1);
      for (let i = 0; i < paramsKey.length; i++) {
        let inputItem = document.createElement("input");
        inputItem.type = "hidden";
        inputItem.name = paramsKey[i];
        inputItem.value = data.data[paramsKey[i]];
        form1.appendChild(inputItem);
      }
      form1.method = type;
      //提交路径
      form1.action = data.url;
      //执行提交
      form1.submit();
      // 删除该 form
      document.body.removeChild(form1);
    },
    getLineCanal() {
      if (
        this.need_params == "null" ||
        this.need_params == "" ||
        this.need_params == undefined
      ) {
        this.need_params = null;
      }
      onLineRecharge({
        canal_id: this.id,
        money: this.money_other,
        ...this.need_params,
      }).then((result) => {
        if (result.data.code == "FAILUE") {
          app.Mint.Toast(result.data.msg);
        } else if (result.data.code == "SUCCESS") {
          let list = [];
          list = result.data.result;
          if (list.type == 1) {
            //post表单
            this.formSubmit(result.data.result, "POST");
          } else if (list.type == 2) {
            //get表单
            this.formSubmit(result.data.result, "GET");
          } else if (list.type == 3) {
            //生成二维码
            this.imgTrue.tf = true;
            this.imgTrue.url = list.url;
            this.listType = 3;
          } else if (list.type == 4) {
            //二维码图片
            this.imgTrue.tf = true;
            this.imgTrue.url = list.url;
          } else if (list.type == 5) {
            //跳转
            window.location.href = list.url;
          } else if (list.type == 6) {
            //HTML
            document.write(list.data);
          }
        } else {
          app.Mint.Toast(result.data.msg);
        }
      });
    },
    money(item) {
      this.items.forEach((item) => {
        if (item.active === true) {
          item.active = false;
        }
      });
      item.active = true;
      this.money_other = item.value;
    },
  },
  mounted() {
    this.need_params = this.$route.params.need_params;
    this.id = this.$route.params.id;
    this.title = this.$route.params.title;
    this.min = this.$route.params.min;
    this.max = this.$route.params.max;
    let remark = "";
    const chldata = JSON.parse(window.localStorage.getItem("channelsData"));
    if (chldata[2]) {
      this.remark = chldata[2].online_remark;
    }else if( chldata[1]){
          this.remark = chldata[1].online_remark;
    }
  },
};
</script>
<template>

  <OnlineTEMPLATE1
    :imgTrue="imgTrue"
    :remark="remark"
    :money="money"
    :items="items"
    :money_other="money_other"
    :getLineCanal="getLineCanal"
  />

</template>
<style lang="scss" scoped type="text/css">
#recharge {
  overflow: auto;
}
</style>